import common from '@/common'

let form_options = {}
form_options.company = []

let showNfcTags = false

export function reloadFieldData() {
  // init Arrays
  ;[
    form_options.company,
  ].forEach(arr => arr.length = 0)

  common.apiGetData('/company/GetCompanyDropDownList')
    .then(res => {
      const company_options = common.getSelectOptions(res.data.companys, 'companyName', 'companyID')
      form_options.company.push.apply(form_options.company, company_options)
    })

  if (common.isAdmin()) {
    showNfcTags = true
  } else {
    common.apiGetData('/facility/GetFacilityAvailableModules?facilityID=' + common.getFacilityId())
      .then(res => {
        showNfcTags = res.data.modules.some(module => module.code === 'CallBell_Menu')
      })
  }
}

export default [
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    options: form_options.company,
    rule: 'required',
    edit_disable: true,
    add_disable: !common.isAdmin()
  },
  {
    name: 'facilityRoleIDs',
    label: 'common.facility',
    type: 'slot',
    show: 'companyID',
    slot: 'facility',
    rule: 'required',
    custom_messages: {
      required: common.getI18n('error.StaffRoleIDRequired')
    }
  },
  {
    name: 'staffCode',
    label: 'staff.staffCode',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'loginName',
    label: 'staff.loginName',
    type: 'text',
    rule: 'required|max: 50',
  },
  {
    name: 'initPassword',
    label: 'staff.initPassword',
    type: 'text',
    rule: 'required|max: 20',
    add_show: true
  },
  {
    name: 'chineseName',
    label: 'staff.chineseName',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'englishName',
    label: 'staff.englishName',
    type: 'text',
    rule: 'required|max: 50',
  },
  {
    name: 'contactEmail',
    label: 'staff.contactEmail',
    type: 'text',
    rule: 'max: 50|email',
  },
  {
    name: 'contactPhone',
    label: 'staff.contactPhone',
    type: 'text',
    rule: 'max: 20|digits: 8',
  },
  {
    name: 'isActive',
    label: 'staff.isActive',
    type: 'select',
    rule: 'required',
    options: [
      {
        text: common.getI18n('staff.active'),
        value: true
      },
      {
        text: common.getI18n('staff.passive'),
        value: false
      },
    ],
  },
  {
    name: 'facilityNfcs',
    label: 'staff.nfc_tag',
    type: 'slot',
    validate_in_slot: true,
    slot: 'facilityNfcs',
    slot_config: {
      name: 'Nfcs',
      label: 'staff.nfc_tag',
      type: 'text',
      min_count: 1,
      edit_disable: true,
      input_class: 'text-monospace',
      check_duplicate: false,
    },
    show_fn: _vm => showNfcTags,
    add_show: false,
    edit_show: true,
    // show: false,
    // show_exp: null
  }
]
